import React, { Component } from "react";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import StatsGrid from "./Stats";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dashboardData: null,
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.fetchDashboardStats();
  }

  fetchDashboardStats = async () => {
    try {
      const response = await MerchantAxiosInstance.post(
        "https://merchant-api.globiancepay.com/admin-dashboard-api-service/reports/get-dashboard-stats"
      );

      this.setState({ dashboardData: response.data.data, loading: false });
    } catch (error) {
      console.error("Error fetching dashboard stats:", error);
      this.setState({ error: "Failed to load data", loading: false });
    }
  };

  render() {
    const { dashboardData, loading, error } = this.state;

    return (
      <div className="main-panel dashboard">
        <div className="placeholder-admin">GLOBIANCE ADMIN MANAGEMENT</div>
        {this.state.dashboardData && <StatsGrid statsData={this.state.dashboardData} />}
        {loading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}
      </div>
    );
  }
}

export default Dashboard;
