import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import moment from "moment";
import { API_MERCHANT_ROOT } from "../../helper/constant";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

const merchantBalanceColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  
  {
    dataField: "amount",
    text: "Amount",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "withdraw",
    text: "Withdraw",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "typeOfRecord",
    text: "Type Of Record",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currencyId",
    text: "Currency Id",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currencyName",
    text: "Currency Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "isActive",
    text: "Active",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },  
 
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

class MerchantBalanceById extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      merchantBalance: { data: [], totalRecords:0, loading: false },
      pageSize: 10,
      currentPage: 0,
      searchParams: { merchantId: "" },
    };


    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getMerchantBalance(0);
  }

  handleChange = ({ currentTarget: input }) => { 
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  handlePage = (page) => {
    this.setState({ currentPage: page });
    this.getMerchantBalance(page);
  }

  handleSearch = () => {
    this.getMerchantBalance(0);
  }

  getMerchantBalance(page) {
    
    const {merchantId} = this.state.searchParams;
    var params = {};
 
    if(merchantId != '') {
      params.merchantId = merchantId;
    
    
    // console.log(params);
    this.generalStateUpdater("merchantBalance", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-balance/get-merchant-balance-by-id`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        const View = (
          {
            modalName: "View Merchant Balance",
            children: <ViewOnlyForm data={e} />,
            backdrop: "static",
            centered: true,
            disableSubmit: true,
            keyboard: false,
          }
        )

        return {
          ...e,
          actions: [
            View
          ],
        };
      },
    });
  } // end of merchant Id check
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("amount");
    return {
      data: this.state[name].data.map((e, i) => {
        if(e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format('YYYY-MM-DD');
        }
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
        <div>
          <Link to='/merchant-admin-balances' className="btn btn-outline-dark tab-btn" style={{ marginRight: "10px"}}>Merchant Balances</Link>
          <Link to='/merchant-admin-balances-by-id' className="btn btn-outline-dark tab-btn active" style={{ marginRight: "10px"}}>Merchant Balances By Id</Link>
          <Link to='/merchant-admin-balances-by-email' className="btn btn-outline-dark tab-btn" style={{ marginRight: "10px"}}>Merchant Balances By Email</Link>
          <Link to='/merchant-admin-balances-afr' className="btn btn-outline-dark tab-btn">Merchant Balances AFR</Link>        
        </div>
        </Card>
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Balances
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Card className="custom-card-1">
                <div className="card-body">
                  <Row>
                  
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Merchant By Id:</Form.Label>
                        <Form.Control type="text" value={this.state.searchParams.merchantId} name="merchantId" onChange={this.handleChange} />
                      </Form.Group>
                    </Col>

                    <Col style={{ marginTop: "25px"}}>
                      <Button variant="primary" type="button" onClick={()=>this.handleSearch()}>
                        Search
                      </Button>
                    </Col>   
                    
                  </Row>
                </div>
            </Card>

            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={merchantBalanceColumn}
                  className="table1"
                  tableName="Merchant Balance"
                  keyField="srNo"
                  onRefresh={this.getMerchantBalance.bind(this)}
                  {...this.renderTableData("merchantBalance")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantBalanceById;
