import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import ToggleModal from "../common/ToggleModal";
import Table from "../common/Table";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  RenderCreateBtn,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import moment from "moment";
import { DynamicActionBtn, DynamicForm } from "../common/RenderCustomView";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

// merchantUserId, providerName, token, status

const mdaDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "merchantUserId",
    text: "Merchant User ID",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "provider",
    text: "Provider Name",
    sort: true,
    classes: "highlight",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    classes: "highlight",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },

  {
    dataField: "createdAt",
    text: "Created At",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "updatedAt",
    text: "Updated At",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: dateFilter({
      onFilter: DateFilter("updatedAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell) => {
      if (cell)
        return cell.map((e, i) => {
          if (e.modalName) return <ToggleModal key={i} {...e} />;
          return e;
        });
    },
  },
];

export class MDA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mda: { data: [], loading: false, totalCount: 0 },
      currencyList: [],
    };
    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getMDA();
  }

  getMDA() {
    this.generalStateUpdater("mda", {
      path: `admin-dashboard-api-service/merchant-direct-acquiring/get-all-merchant-direct-acquiring`,
      method: "POST",
      postProcessor: (e) => {
        const enableAPI =
          "admin-dashboard-api-service/merchant-direct-acquiring/enable-merchant-direct-acquiring";
        const disbleAPI =
          "admin-dashboard-api-service/merchant-direct-acquiring/disable-merchant-direct-acquiring";

        const Enable = (
          <DynamicActionBtn
            type="actionBtnMerchant"
            api={enableAPI}
            options={{
              variant: "warning",
              name: "Enable",
              data: {
                mdaId: e.uniqueId,
              },
            }}
          />
        );

        const Disable = (
          <DynamicActionBtn
            type="actionBtnMerchant"
            api={disbleAPI}
            options={{
              variant: "danger",
              name: "Disable",
              data: {
                mdaId: e.uniqueId,
              },
            }}
          />
        );

        return {
          ...e,
          actions: [
            {
              modalName: "View Merchant Direct Acquiring",
              children: <ViewOnlyForm data={e} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
            },
            Enable,
            Disable,
          ],
        };
      },
    });
  }

  createMDA() {
    const data = [
      { name: "merchantUserId", type: "text", placeholder: "merchantUserId" },
      { name: "providerName", type: "text", placeholder: "providerName" },
      { name: "token", type: "text", placeholder: "token" },
      {
        name: "status",
        type: "radio",
        options: [
          { label: "true", value: true },
          { label: "false", value: false },
        ],
      },
      // { name : "image", type : "file", accept: ".png" },
      // { name : "image", type : "file", accept: "image/jpg, image/jpeg, image/png" },
    ];
    const api = `/admin-dashboard-api-service/merchant-direct-acquiring/create-merchant-direct-acquiring`;

    return RenderCreateBtn(data, api, "Create");
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("name");
    return {
      data: this.state[name].data
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
        .map((e, i) => {
          if (e.createdAt != null) {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD HH:mm:ss");
          }
          if (e.updatedAt != null) {
            e.updatedAt = moment(e.updatedAt).format("YYYY-MM-DD HH:mm:ss");
          }
          return {
            srNo: i + 1,
            ...e,
          };
        }),
    };
  }

  render() {
    console.log("this.state[name].data", this.state.mda.data);

    return (
      <div className="user-wallet main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Direct Acquiring
            <div className="title--bottom"></div>
          </div>

          <div className="card-body">
            <Row className="mt-3 mt-md-5">
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={mdaDataColumn}
                  className="table1"
                  tableName="Merchant Direct Acquiring"
                  keyField="srNo"
                  createBtn={<ToggleModal {...this.createMDA()} />}
                  onRefresh={this.getMDA.bind(this)}
                  {...this.renderTableData("mda")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default MDA;
