import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import ToggleModal from "../common/ToggleModal";
import Table from "../common/Table";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  RenderCreateBtn,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import moment from "moment";
import { DynamicActionBtn, DynamicForm } from "../common/RenderCustomView";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import {
  dateFilter,
  numberFilter,
  textFilter,
} from "react-bootstrap-table2-filter";

/**
 * 
 * 
 * merchantUserId: string;
    merchantDAId: string;
    merchantCheckoutId: string;
    externalReferenceId: string;
    metadata: string;
    amount: number;
    currency: string;
    status: string;
    processed: boolean;
 * 
 */

const mdaTxnDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "User.email",
    text: "Merchant User",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "merchantCheckoutId",
    text: "Checkout ID",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "externalReferenceId",
    text: "EXT REF ID",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "amount",
    text: "amount",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: numberFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currency",
    text: "Currency",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    classes: "highlight",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "processed",
    text: "Processed",
    sort: true,
    classes: "highlight",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: DefaultHeadFormatter,
  },

  {
    dataField: "updatedAt",
    text: "Updated At",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: dateFilter({
      onFilter: DateFilter("updatedAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell) => {
      if (cell)
        return cell.map((e, i) => {
          if (e.modalName) return <ToggleModal key={i} {...e} />;
          return e;
        });
    },
  },
];

export class MDATXN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mdatxn: { data: [], loading: false, totalCount: 0 },
      currencyList: [],
    };
    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getMDATXN();
  }

  getMDATXN() {
    this.generalStateUpdater("mdatxn", {
      path: `admin-dashboard-api-service/merchant-direct-acquiring/get-all-merchant-direct-acquiring-txn`,
      method: "POST",
      postProcessor: (e) => {
        return {
          ...e,
          actions: [
            {
              modalName: "View Merchant Direct Acquiring TXN",
              children: <ViewOnlyForm data={e} />,
              backdrop: "static",
              centered: true,
              disableSubmit: true,
              keyboard: false,
            },
          ],
        };
      },
    });
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("name");
    return {
      data: this.state[name].data
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
        .map((e, i) => {
          if (e.createdAt != null) {
            e.createdAt = moment(e.createdAt).format("YYYY-MM-DD HH:mm:ss");
          }
          if (e.updatedAt != null) {
            e.updatedAt = moment(e.updatedAt).format("YYYY-MM-DD HH:mm:ss");
          }
          return {
            srNo: i + 1,
            ...e,
          };
        }),
    };
  }

  render() {

    return (
      <div className="user-wallet main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Direct Acquiring TXN
            <div className="title--bottom"></div>
          </div>

          <div className="card-body">
            <Row className="mt-3 mt-md-5">
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={mdaTxnDataColumn}
                  className="table1"
                  tableName="Merchant Direct Acquiring TXN"
                  keyField="srNo"
                  onRefresh={this.getMDATXN.bind(this)}
                  {...this.renderTableData("mdatxn")}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    );
  }
}

export default MDATXN;
