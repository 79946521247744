import DashboardAdmin from "./DashboardAdmin";

const Dashboard = (props) => {
  return (
    <div className="main-panel">
      <DashboardAdmin {...props} />;
    </div>
  );
};

export default Dashboard;
