import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  DateFilter,
  IconHeader,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import moment from "moment";
import { API_MERCHANT_ROOT } from "../../helper/constant";
import { RemoveExpo } from "../../helper/decimals";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";
import ReactSelect from "react-select";

const balanceSummaryDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "summaryId",
    text: "Summary ID",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "timestamp",
    text: "Date",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

const balanceTallyDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "uniqueId",
    text: "Unique Id",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "currencyName",
    text: "Currency Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "actualBalanceChange",
    text: "Actual Balance Change",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "accountedBalanceChange",
    text: "Accounted Balance Change",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

const unmatchedTallyDataColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "uniqueId",
    text: "TallyID",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "createdAt",
    text: "Created At",
    filter: dateFilter({
      onFilter: DateFilter("createdAt"),
    }),
    headerFormatter: DefaultHeadFormatter,
  },
  {
    dataField: "currencyName",
    text: "Currency Name",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "accountedBalanceChange",
    text: "Accounted Balance Change",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "actualBalanceChange",
    text: "Actual Balance Change",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "diff",
    text: "Difference",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
];

class MerchantBalancesTally extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      balanceSummary: { data: [], totalRecords: 0, loading: false },
      balanceTally: { data: [], totalRecords: 0, loading: false },
      unmatchedTally: { data: [], loading: false },
      searchParams: {
        to: "",
        from: "",
        docVersion: "",
        tou: "",
        fromu: "",
        docVersionu: "",
        toTally: "",
        fromTally: "",
        docVersionTally: "",
      },
      pageSize: 10,
      currentPage: 0,
    };

    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getMerchantBalanceSummary();
    this.getMerchantBalanceTally(0);
    this.getMerchantUnmatchedTally();

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    var yyyy = today.getFullYear();
    var options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    today = yyyy + "-" + mm + "-" + dd;

    var toDate = new Date(today + "T23:59:00"); // Set "to" date to today at 23:59

    var fromDate = new Date(toDate); // Create a copy of "to" date
    fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the "from" date

    var fd = String(fromDate.getDate()).padStart(2, "0");
    var fm = String(fromDate.getMonth() + 1).padStart(2, "0"); // January is 0!
    var fy = fromDate.getFullYear();

    var fromdate = fy + "-" + fm + "-" + fd;

    const searchParams = { ...this.state.searchParams };
    searchParams["to"] = moment(toDate.toLocaleString("en-US", options)).format(
      "YYYY-MM-DD HH:mm:ss",
    );
    searchParams["from"] = fromdate + " 00:00:00"; // Set "from" date to YYYY-MM-DD 00:00:00 format

    searchParams["toTally"] = moment(
      toDate.toLocaleString("en-US", options),
    ).format("YYYY-MM-DD HH:mm:ss");
    searchParams["fromTally"] = fromdate + " 00:00:00"; // Set "from" date to YYYY-MM-DD 00:00:00 format

    searchParams["tou"] = moment(
      toDate.toLocaleString("en-US", options),
    ).format("YYYY-MM-DD HH:mm:ss");
    searchParams["fromu"] = fromdate + " 00:00:00";

    this.setState({ searchParams });
  }

  handleSummaryChange = ({ currentTarget: input }) => {
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  handleBalanceSummarySearch = () => {
    this.getMerchantBalanceSummary();
  };

  handleTallyChange = ({ currentTarget: input }) => {
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  handleBalanceTallySearch = () => {
    this.getMerchantBalanceTally(0);
  };

  handleUnmatchedTallyChange = ({ currentTarget: input }) => {
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  handleUnmatchedTallySearch = () => {
    this.getMerchantUnmatchedTally();
  };

  getMerchantBalanceSummary() {
    const { to, from, docVersion } = this.state.searchParams;
    var params = {};
    if (to != "") {
      params.to = moment(to).toISOString();
    }
    if (from != "") {
      params.from = moment(from).toISOString();
    }
    if (docVersion != "") {
      params.docVersion = docVersion;
    }

    // console.log(params);
    this.generalStateUpdater("balanceSummary", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/balance-tally/get-balance-summary-group`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        const View = {
          modalName: "View Merchant Balance Summary",
          children: <ViewOnlyForm data={e} />,
          backdrop: "static",
          centered: true,
          disableSubmit: true,
          keyboard: false,
        };
        return {
          ...e,
          actions: [View],
        };
      },
    });
  }

  handlePage = (page) => {
    this.setState({ currentPage: page });
    this.getMerchantBalanceTally(page);
  };

  getMerchantBalanceTally(page) {
    const { toTally, fromTally, docVersionTally } = this.state.searchParams;
    let params = {
      page: page,
      limit: this.state.pageSize,
    };

    if (toTally != "") {
      params.to = moment(toTally).toISOString();
    }
    if (fromTally != "") {
      params.from = moment(fromTally).toISOString();
    }
    if (docVersionTally != "") {
      params.docVersion = docVersionTally;
    }

    // console.log(params);
    this.generalStateUpdater("balanceTally", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/balance-tally/get-balance-tally`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        const View = {
          modalName: "View Merchant Balance Tally",
          children: <ViewOnlyForm data={e} />,
          backdrop: "static",
          centered: true,
          disableSubmit: true,
          keyboard: false,
        };
        return {
          ...e,
          actions: [View],
        };
      },
    });
  }

  getMerchantUnmatchedTally() {
    const { tou, fromu, docVersionu } = this.state.searchParams;
    var params = {};
    if (tou != "") {
      params.to = moment(tou).toISOString();
    }
    if (fromu != "") {
      params.from = moment(fromu).toISOString();
    }
    if (docVersionu != "") {
      params.docVersion = docVersionu;
    }

    // console.log(params);
    this.generalStateUpdater("unmatchedTally", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/balance-tally/get-unmatched-tally`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        // const View = (
        //   {
        //     modalName: "View Merchant Balance Summary",
        //     children: <ViewOnlyForm data={e} />,
        //     backdrop: "static",
        //     centered: true,
        //     disableSubmit: true,
        //     keyboard: false,
        //   }
        // )
        return {
          ...e,
          // actions: [
          //   View
          // ],
        };
      },
    });
  }

  handleCreateTally = () => {
    MerchantAxiosInstance({
      method: "POST",
      url: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/balance-tally/create-tally`,
    })
      .then((resp) => {
        console.log(resp);
        if (resp.status === 200) {
          AddNoti("Created Successfully", { type: "success" });
        } else {
          AddNoti(ParseError(resp), { type: "error" });
        }
      })
      .catch((e) => {
        AddNoti(ParseError(e), { type: "error" });
      })
      .finally(() => {});
  };

  renderBalancesummaryTableData(name) {
    if (this.state[name].loading === true) return TableLoader("srNo");
    return {
      data: this.state[name].data.map((e, i) => {
        if (e.timestamp != null) {
          e.timestamp = moment(e.timestamp).format("YYYY-MM-DD HH:mm:ss");
        }

        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }

  renderTableData(name) {
    if (name === "balanceTally") {
      const { currentPage, pageSize } = this.state;
      let srNo = currentPage * pageSize;
    }

    if (this.state[name].loading === true) return TableLoader("srNo");
    return {
      data: this.state[name].data.map((e, i) => {
        if (e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format("YYYY-MM-DD HH:mm:ss");
        }

        console.log(e);

        e.diff = RemoveExpo(e.diff);

        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          <div>
            <Link
              to="/merchant-balances-tally"
              className="btn btn-outline-dark tab-btn active"
              style={{ marginRight: "10px" }}
            >
              Balances Tally
            </Link>
            <Link
              to="/merchant-balances-payment-distribution"
              className="btn btn-outline-dark tab-btn"
            >
              Payment Distribution
            </Link>
          </div>
        </Card>
        <Card className="custom-card-1">
          <div className="title">
            Balance Summary
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Card className="custom-card-1">
              <div className="card-body">
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>From:</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        defaultValue={this.state.searchParams.from}
                        value={this.state.searchParams.from}
                        name="from"
                        onChange={this.handleSummaryChange}
                        style={{ height: "38px" }}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>To:</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        value={this.state.searchParams.to}
                        name="to"
                        onChange={this.handleSummaryChange}
                        style={{ height: "38px" }}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Doc Version:</Form.Label>
                      <ReactSelect
                        defaultValue={{ label: "All Version", value: "" }}
                        options={[
                          {
                            label: "All Version",
                            value: "",
                          },
                          {
                            label: "Null",
                            value: "null",
                          },
                          {
                            label: "2",
                            value: 2,
                          },
                        ]}
                        onChange={(e) => {
                          this.setState({
                            searchParams: {
                              ...this.state.searchParams,
                              docVersion: e.value,
                            },
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col style={{ marginTop: "25px" }}>
                    <Button
                      variant="primary"
                      type="button"
                      style={{ height: "38px" }}
                      onClick={() => this.handleBalanceSummarySearch()}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>

            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={balanceSummaryDataColumn}
                  className="table1"
                  tableName="Balance Summary "
                  keyField="srNo"
                  onRefresh={this.getMerchantBalanceSummary.bind(this)}
                  {...this.renderBalancesummaryTableData("balanceSummary")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>

        <Card className="custom-card-1">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div class="title" style={{ marginRight: "10px" }}>
              Balance Tally
              <div class="title--bottom"></div>
            </div>
            <div style={{ marginRight: "15px" }}>
              <button
                onClick={this.handleCreateTally}
                className="btn btn-warning"
              >
                Create Tally
              </button>
            </div>
          </div>

          <div className="card-body">
            <Card className="custom-card-1">
              <div className="card-body">
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>From:</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        defaultValue={this.state.searchParams.fromTally}
                        value={this.state.searchParams.fromTally}
                        name="fromTally"
                        onChange={this.handleTallyChange}
                        style={{ height: "38px" }}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>To:</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        value={this.state.searchParams.toTally}
                        name="toTally"
                        onChange={this.handleTallyChange}
                        style={{ height: "38px" }}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Doc Version:</Form.Label>
                      <ReactSelect
                        defaultValue={{ label: "All Version", value: "" }}
                        options={[
                          {
                            label: "All Version",
                            value: "",
                          },
                          {
                            label: "Null",
                            value: "null",
                          },
                          {
                            label: "2",
                            value: 2,
                          },
                        ]}
                        onChange={(e) => {
                          this.setState({
                            searchParams: {
                              ...this.state.searchParams,
                              docVersionTally: e.value,
                            },
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col style={{ marginTop: "25px" }}>
                    <Button
                      variant="primary"
                      type="button"
                      style={{ height: "38px" }}
                      onClick={() => this.handleBalanceTallySearch()}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={balanceTallyDataColumn}
                  className="table1"
                  tableName="Merchant Balance Tally"
                  keyField="srNo"
                  customPagination="custom"
                  totalCount={this.state.balanceTally.totalRecords}
                  pageSize={this.state.pageSize}
                  pageNumber={this.state.currentPage}
                  onPageChange={this.handlePage}
                  // onRefresh={this.getMerchantBalanceTally.bind(this)}
                  {...this.renderTableData("balanceTally")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>

        <Card className="custom-card-1">
          <div className="title">
            Unmatched Tally
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Card className="custom-card-1">
              <div className="card-body">
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>From:</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        defaultValue={this.state.searchParams.fromu}
                        value={this.state.searchParams.fromu}
                        name="fromu"
                        onChange={this.handleUnmatchedTallyChange}
                        style={{ height: "38px" }}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>To:</Form.Label>
                      <Form.Control
                        type="datetime-local"
                        value={this.state.searchParams.tou}
                        name="tou"
                        onChange={this.handleUnmatchedTallyChange}
                        style={{ height: "38px" }}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Doc Version:</Form.Label>
                      <ReactSelect
                        defaultValue={{ label: "All Version", value: "" }}
                        options={[
                          {
                            label: "All Version",
                            value: "",
                          },
                          {
                            label: "Null",
                            value: "null",
                          },
                          {
                            label: "2",
                            value: 2,
                          },
                        ]}
                        onChange={(e) => {
                          this.setState({
                            searchParams: {
                              ...this.state.searchParams,
                              docVersionu: e.value,
                            },
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col style={{ marginTop: "25px" }}>
                    <Button
                      variant="primary"
                      type="button"
                      onClick={() => this.handleUnmatchedTallySearch()}
                      style={{ height: "38px" }}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={unmatchedTallyDataColumn}
                  className="table1"
                  tableName="Unmatched Tally"
                  keyField="srNo"
                  onRefresh={this.getMerchantUnmatchedTally.bind(this)}
                  {...this.renderTableData("unmatchedTally")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantBalancesTally;
