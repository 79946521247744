import React, { useState, useEffect } from "react";
import { Card, Col, Row, Form } from "react-bootstrap";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import { useParams, useHistory, Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { API_MERCHANT_ROOT } from "../../helper/constant";
import ToggleModal from "../common/ToggleModal";
import { RenderUpdateBtn } from "../../utils/Table";

const MerchantPaymentDetail = (props) => {
    const payment = useParams();
    const history = useHistory();
    const [paymentDetail, setPaymentDetail] = useState({});
    const [quotes, setQuotes] = useState({});
    const [settlementOrder, setSettlementOrder] = useState({});
    const [settlementFee, setSettlementFee] = useState({});
    const [transactionDetail, setTransactionDetail] = useState({});
    const [paymenStatusHistory, setPaymenStatusHistory] = useState({});
    const [merchantRefund, setMerchantRefund] = useState("");

    const getMerchantPaymentDetails = (id) => {
        MerchantAxiosInstance.post(
            `${API_MERCHANT_ROOT}/admin-dashboard-api-service/payment/get-payment-detail`,
            { paymentId: id }
        )
            .then((resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    setPaymentDetail(resp.data.data.payment);
                    setQuotes(resp.data.data.quotes);
                    setSettlementOrder(resp.data.data.merchantSettlementOrder);
                    setSettlementFee(resp.data.data.merchantFeeSettlement);
                    setTransactionDetail(resp.data.data.transactionDetail);
                    setPaymenStatusHistory(resp.data.data.paymenStatusHistory);
                    setMerchantRefund(resp.data.data.merchantRefund);

                    // console.log(resp.data.data.merchantSettlementOrder);
                } else {
                    AddNoti(ParseError(resp), { type: "error" });
                }
            })
            .catch((e) => {
                AddNoti(ParseError(e), { type: "error" });
            });
    };

    useEffect(() => {
        const uniqueId = payment.id;

        if (!uniqueId) return true;
        getMerchantPaymentDetails(uniqueId);
    }, []);

    const handleRetrySettlement = (uniqueId) => {
        MerchantAxiosInstance.post(
            `${API_MERCHANT_ROOT}/admin-dashboard-api-service/payment/retry-settlement`,
            { paymentId: uniqueId }
        )
            .then((resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    AddNoti(ParseError(resp.data.message), { type: "success" });
                    getMerchantPaymentDetails(uniqueId);
                } else {
                    AddNoti(ParseError(resp), { type: "error" });
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    const errorMessage =
                        error.response.data &&
                        error.response.data.error &&
                        error.response.data.error.message;
                    AddNoti(errorMessage, { type: "error" });
                } else {
                    AddNoti(ParseError(error), { type: "error" });
                }
            });
    };

    const handleReAlertWebhook = (uniqueId) => {
        MerchantAxiosInstance.post(
            `${API_MERCHANT_ROOT}/admin-dashboard-api-service/payment/re-alert-payment`,
            { paymentId: uniqueId }
        )
            .then((resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    AddNoti((resp.data.message), { type: "success" });
                    // getMerchantPaymentDetails(uniqueId);
                } else {
                    AddNoti(ParseError(resp), { type: "error" });
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    const errorMessage =
                        error.response.data &&
                        error.response.data.error &&
                        error.response.data.error.message;
                    AddNoti(errorMessage, { type: "error" });
                } else {
                    AddNoti(ParseError(error), { type: "error" });
                }
            });
    };

    const handleForceDistribute = (uniqueId) => {
        MerchantAxiosInstance.post(
            `${API_MERCHANT_ROOT}/admin-dashboard-api-service/payment/force-distribute`,
            { paymentId: uniqueId }
        )
            .then((resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    AddNoti((resp.data.message), { type: "success" });
                    getMerchantPaymentDetails(uniqueId);
                } else {
                    AddNoti(ParseError(resp), { type: "error" });
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    const errorMessage =
                        error.response.data &&
                        error.response.data.error &&
                        error.response.data.error.message;
                    AddNoti(errorMessage, { type: "error" });
                } else {
                    AddNoti(ParseError(error), { type: "error" });
                }
            });
    };

    const approveDisputedPayment = (e) => {
        e.preventDefault();
        const payload = { paymentId: paymentDetail.uniqueId };
        MerchantAxiosInstance.post(
            "/admin-dashboard-api-service/payment/approve-disputed-payment",
            payload
        )
            .then((resp) => {
                console.log("resp", resp);
                if (resp.status === 200 || resp.status === 201) {
                    AddNoti((resp.data.message), { type: "success" });
                    getMerchantPaymentDetails(paymentDetail.uniqueId);
                } else {
                    AddNoti(ParseError(resp), { type: "error" });
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    const errorMessage =
                        error.response.data &&
                        error.response.data.error &&
                        error.response.data.error.message;
                    AddNoti(errorMessage, { type: "error" });
                } else {
                    AddNoti(ParseError(error), { type: "error" });
                }
            });
    };

    const refundDisputedPaymentConfigButton = () => {
        const refundDisputedPaymentAPI = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/payment/refund-disputed-payment`;

        const formData = [
            {
                name: "paymentId",
                value: paymentDetail.uniqueId,
                defaultValue: paymentDetail.uniqueId,
                disabled: true,
            },
            { name: "toAddress", placeholder: "Enter address" },
            { name: "toDestinationTag", placeholder: "Enter destination tag" },
        ];

        const btnDisabled =
            paymentDetail && paymentDetail.isUnderDispute ? false : true;
        const actionCallback = () =>
            getMerchantPaymentDetails(paymentDetail.uniqueId);

        return RenderUpdateBtn(
            formData,
            refundDisputedPaymentAPI,
            "Refund Disputed Payment",
            false,
            "Refund Disputed Payment",
            btnDisabled,
            actionCallback
        );
    };

    const partiallyRefundDisputedPaymentConfigButton = () => {
        const refundDisputedPaymentAPI = `${API_MERCHANT_ROOT}/admin-dashboard-api-service/payment/partially-refund-disputed-payment`;

        const formData = [
            {
                name: "paymentId",
                value: paymentDetail.uniqueId,
                defaultValue: paymentDetail.uniqueId,
                disabled: true,
            },
            {
                name: "refundAmount",
                type: "number",
                placeholder: "Enter refund amount",
            },
            { name: "toAddress", placeholder: "Enter address" },
            { name: "toDestinationTag", placeholder: "Enter destination tag" },
        ];

        const btnDisabled =
            paymentDetail && paymentDetail.isUnderDispute ? false : true;
        const actionCallback = () =>
            getMerchantPaymentDetails(paymentDetail.uniqueId);

        return RenderUpdateBtn(
            formData,
            refundDisputedPaymentAPI,
            "Partially Refund Disputed Payment",
            false,
            "Partially Refund Disputed Payment",
            btnDisabled,
            actionCallback
        );
    };

    return (
        <div className='kyb main-panel'>
            <form>
                <Card className='custom-card-1'>
                    {/* Payment Details Block */}
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='title'>
                            Payment Details
                            <div className='title--bottom'></div>
                        </div>
                        <div className='mr-4'>
                            {props.location.state.data.parent ===
                                "Payments" && (
                                <div>
                                    <Link to='/merchant-admin-payment'>
                                        Payments
                                    </Link>{" "}
                                    /{" "}
                                    <span style={{ color: "grey" }}>
                                        {" "}
                                        {paymentDetail &&
                                            paymentDetail.uniqueId}{" "}
                                    </span>
                                </div>
                            )}
                            {props.location.state.data.parent !==
                                "Payments" && (
                                <div>
                                    <Link to='/merchant-admin-checkout'>
                                        Checkout
                                    </Link>{" "}
                                    /{" "}
                                    <Link
                                        to={`/merchant-admin-checkout-detail/${
                                            paymentDetail &&
                                            paymentDetail.checkoutId
                                        }`}
                                    >
                                        {paymentDetail &&
                                            paymentDetail.checkoutId}
                                    </Link>{" "}
                                    /{" "}
                                    <span style={{ color: "grey" }}>
                                        {" "}
                                        {paymentDetail &&
                                            paymentDetail.uniqueId}{" "}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='d-flex justify-content-between align-items-center flex-column flex-md-row align-items-center justify-content-center flex-wrap'>
                        <div className='ml-md-4 d-flex flex-column flex-md-row align-items-center justify-content-center flex-wrap'>
                            <button
                                size='sm'
                                className='btn btn-success mt-0 mr-1'
                                type='button'
                                onClick={(e) => approveDisputedPayment(e)}
                                disabled={
                                    paymentDetail &&
                                    paymentDetail.isUnderDispute
                                        ? false
                                        : true
                                }
                            >
                                Approve Disputed Payment
                            </button>
                            <ToggleModal
                                {...refundDisputedPaymentConfigButton()}
                            />
                            <div className='ml-1'>
                                <ToggleModal
                                    {...partiallyRefundDisputedPaymentConfigButton()}
                                />
                            </div>
                        </div>
                        <div className='mr-md-4 mt-2 mt-md-0'>
                            <button
                                type="button"
                                disabled={
                                  false
                                    // paymentDetail &&
                                    // paymentDetail.settlementStatus === "error"
                                    //     ? false
                                    //     : true
                                }
                                className='btn btn-primary'
                                onClick={() =>
                                    paymentDetail &&
                                    handleReAlertWebhook(
                                        paymentDetail.uniqueId
                                    )
                                }
                                style={{ marginRight: "10px" }}
                            >
                                Re Alert Webhook
                            </button>
                            <button
                                type="button"
                                disabled={
                                  false
                                    // paymentDetail &&
                                    // paymentDetail.settlementStatus === "error"
                                    //     ? false
                                    //     : true
                                }
                                className='btn btn-primary'
                                onClick={() =>
                                    paymentDetail &&
                                    handleRetrySettlement(
                                        paymentDetail.uniqueId
                                    )
                                }
                                style={{ marginRight: "10px" }}
                            >
                                Retry Settlement
                            </button>
                            <button
                                type="button"
                                disabled={
                                  false
                                    // paymentDetail &&
                                    // paymentDetail.settlementStatus === "error"
                                    //     ? false
                                    //     : true
                                }
                                className='btn btn-primary'
                                onClick={() =>
                                    paymentDetail &&
                                    handleForceDistribute(
                                        paymentDetail.uniqueId
                                    )
                                }
                            >
                                Force Distribute
                            </button>
                        </div>
                    </div>
                    <div className='card-body'>
                        <Row>
                            <Col lg={12} sm={12} md={12}>
                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Merchant User ID
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.merchantUserId
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Merchant Key ID
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.merchantKeyId
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Checkout Id</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.checkoutId
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Currency Id (Usd)
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.currencyId
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Currency Name
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.currencyName
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Transaction Type
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.transactionType
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Transaction Id Wallet
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.transactionIdWallet
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Transaction Id Blockchain
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.transactionIdBlockchain
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Is Under Dispute
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.isUnderDispute
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Dispute Type
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.disputeType
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>To Settle</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.toSettle
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Settlement Pair Available
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.settlementPairAvailable
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Settlement Status
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.settlementStatus
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Settlement Order Id
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.settlementOrderId
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.amount
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Amount Distributed
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.amountDistributed
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Amount Refunded
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.amountRefunded
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Refunded</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.refunded
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Refund Type</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.refundType
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Refund Status
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.refundStatus
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Refund Id</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.refundId
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.description
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Reason</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.reason
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.status
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Active</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    paymentDetail.isActive
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Created At</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    moment(
                                                        paymentDetail.createdAt
                                                    ).format(
                                                        "YYYY-MM-DD H:mm:ss"
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Updated At</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    paymentDetail &&
                                                    moment(
                                                        paymentDetail.updatedAt
                                                    ).format(
                                                        "YYYY-MM-DD H:mm:ss"
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    {/* Quotes block */}
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='title'>
                            Quotes
                            <div className='title--bottom'></div>
                        </div>
                        <div className='mr-4'>&nbsp;</div>
                    </div>
                    <div className='card-body'>
                        <Row>
                            <Col lg={12} sm={12} md={12}>
                                <Row>
                                    <Col lg={12} sm={12} md={12}>
                                        <Row
                                            style={{
                                                marginLeft: "5px",
                                                marginRight: "5px",
                                            }}
                                        >
                                            <Table
                                                striped
                                                bordered
                                                hover
                                                responsive
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Sr#</th>
                                                        <th>
                                                            Merchant User ID
                                                        </th>
                                                        <th>Checkout ID</th>
                                                        <th>Currency ID</th>
                                                        <th>Currency Name</th>
                                                        <th>
                                                            Promised Settlement
                                                            Rate User
                                                        </th>
                                                        <th>
                                                            Promised Settlement
                                                            Rate Merchant
                                                        </th>
                                                        <th>
                                                            Promised Settlement
                                                            Rate Globiance
                                                        </th>
                                                        <th>Price User</th>
                                                        <th>Price Merchant</th>
                                                        <th>Price Globiance</th>
                                                        <th>Amount USD</th>
                                                        <th>
                                                            Amount Globiance
                                                        </th>
                                                        <th>Amount Merchant</th>
                                                        <th>Amount User</th>
                                                        <th>GHFP</th>
                                                        <th>GOFP</th>
                                                        <th>MOFP</th>
                                                        <th>MHFP</th>
                                                        <th>gafOneP</th>
                                                        <th>gafTwoP</th>
                                                        <th>mafOneP</th>
                                                        <th>mafTwoP</th>
                                                        <th>Created At</th>
                                                        <th>Updated At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {quotes.length === 0 && (
                                                        <tr>
                                                            <td
                                                                colSpan={15}
                                                                align='center'
                                                            >
                                                                No Record Found
                                                            </td>
                                                        </tr>
                                                    )}
                                                    {quotes.length > 0 &&
                                                        quotes.map(
                                                            (item, index) => (
                                                                <tr>
                                                                    <td>
                                                                        {index +
                                                                            1}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.merchantUserId
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.checkoutId
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.currencyId
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.currencyName
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.promisedSettlementRateUser
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.promisedSettlementRateMerchant
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.promisedSettlementRateGlobiance
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.priceUser
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.priceMerchant
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.priceGlobiance
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.amountUsd
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.amountGlobiance
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.amountMerchant
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.amountUser
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.GHFP
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.GOFP
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.MOFP
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.MHFP
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.gafOneP
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.gafTwoP
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.mafOneP
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.mafTwoP
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {moment(
                                                                            item.createdAt
                                                                        ).format(
                                                                            "YYYY-MM-DD H:mm:ss"
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {moment(
                                                                            item.updatedAt
                                                                        ).format(
                                                                            "YYYY-MM-DD H:mm:ss"
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                </tbody>
                                            </Table>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    {/* Settlement Order block */}
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='title'>
                            Settlement Order
                            <div className='title--bottom'></div>
                        </div>
                        <div className='mr-4'>&nbsp;</div>
                    </div>
                    <div className='card-body'>
                        <Row>
                            <Col lg={12} sm={12} md={12}>
                                <Row
                                    style={{
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                    }}
                                >
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>Sr#</th>
                                                <th>Merchant User Id</th>
                                                <th>Merchant Key Id</th>
                                                <th>PaymentId</th>
                                                <th>From Currency Id</th>
                                                <th>To Currency Id</th>
                                                <th>Broker</th>
                                                <th>Rate</th>
                                                <th>Total Received</th>
                                                <th>Status</th>
                                                <th>Response</th>
                                                <th>Amount</th>
                                                <th>Settlement Order Id</th>
                                                <th>Created At</th>
                                                <th>Updated At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {settlementOrder.length === 0 && (
                                                <tr>
                                                    <td
                                                        colSpan={15}
                                                        align='center'
                                                    >
                                                        No Record Found
                                                    </td>
                                                </tr>
                                            )}
                                            {settlementOrder.length > 0 &&
                                                settlementOrder.map(
                                                    (item, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {
                                                                    item.merchantUserId
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.merchantKeyId
                                                                }
                                                            </td>
                                                            <td>
                                                                {item.paymentId}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.fromCurrencyId
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.toCurrencyId
                                                                }
                                                            </td>
                                                            <td>
                                                                {item.broker}
                                                            </td>
                                                            <td>{item.rate}</td>
                                                            <td>
                                                                {
                                                                    item.totalReceived
                                                                }
                                                            </td>
                                                            <td>
                                                                {item.status}
                                                            </td>
                                                            <td>
                                                                {item.response}
                                                            </td>
                                                            <td>
                                                                {item.amount}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.settlementOrderId
                                                                }
                                                            </td>
                                                            <td>
                                                                {moment(
                                                                    item.createdAt
                                                                ).format(
                                                                    "YYYY-MM-DD H:mm:ss"
                                                                )}
                                                            </td>
                                                            <td>
                                                                {moment(
                                                                    item.updatedAt
                                                                ).format(
                                                                    "YYYY-MM-DD H:mm:ss"
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                        </tbody>
                                    </Table>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    {/* Settlement Fee block */}
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='title'>
                            Merchant Settlement Fee
                            <div className='title--bottom'></div>
                        </div>
                        <div className='mr-4'>&nbsp;</div>
                    </div>
                    <div className='card-body'>
                        <Row>
                            <Col lg={12} sm={12} md={12}>
                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Merchant User ID
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.merchantUserId
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Checkout ID</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.checkoutId
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Payment ID</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.paymentId
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Fee Quote Id</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.feeQuoteId
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Payment Currency Id
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.paymentCurrencyId
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Payment Currency Name
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.paymentCurrencyName
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Settlement Currency Id
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.settlementCurrencyId
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Settlement Currency Name
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.settlementCurrencyName
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Payment Currency Amount Received
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.paymentCurrencyAmountReceived
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Payment Currency Price Globiance
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.paymentCurrencyPriceGlobiance
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Payment Currency Price Merchant
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.paymentCurrencyPriceMerchant
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Payment Currency Price User
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.paymentCurrencyPriceUser
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Settlement Rate Globiance
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.settlementRateGlobiance
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Settlement Rate Merchant
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.settlementRateMerchant
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Settlement Rate User
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.settlementRateUser
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Settlement Amount Globiance
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.settlementAmountGlobiance
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Settlement Amount Merchant
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.settlementAmountMerchant
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Settlement Amount User
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.settlementAmountUser
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Settled Amount
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.settledAmount
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Settled Amount Base
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.settledAmountBase
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Merchant Credit
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.merchantCredit
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>GHF</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.GHF
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>GOF</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.GOF
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>MOF</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.MOF
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>MHF</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.MHF
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>GOF_CREDIT</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.GOF_CREDIT
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>gafOne</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.gafOne
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>gafTwo</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.gafTwo
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>MOF_CREDIT</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.MOF_CREDIT
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>mafOne</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.mafOne
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>mafTwo</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.mafTwo
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Promised Rate
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.promisedRate
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Promise Broke
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.promiseBroke
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Promise Delta
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.promiseDelta
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Promise Delta P
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.promiseDeltaP
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Created At</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    moment(
                                                        settlementFee.createdAt
                                                    ).format(
                                                        "YYYY-MM-DD H:mm:ss"
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Updated At</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    moment(
                                                        settlementFee.updatedAt
                                                    ).format(
                                                        "YYYY-MM-DD H:mm:ss"
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>Deleted At</Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    settlementFee &&
                                                    settlementFee.deletedAt &&
                                                    moment(
                                                        settlementFee.deletedAt
                                                    ).format(
                                                        "YYYY-MM-DD H:mm:ss"
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>&nbsp;</Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    {/* Transaction Detail block */}
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='title'>
                            Transaction Detail
                            <div className='title--bottom'></div>
                        </div>
                        <div className='mr-4'>&nbsp;</div>
                    </div>
                    <div className='card-body'>
                        <Row>
                            <Col lg={12} sm={12} md={12}>
                                {paymentDetail &&
                                    paymentDetail.transactionType ===
                                        "wallet" && (
                                        <>
                                            <Row>
                                                <Col>
                                                    <Form.Group
                                                        className='mb-3'
                                                        controlId='formBasicEmail'
                                                    >
                                                        <Form.Label>
                                                            Amount
                                                        </Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            disabled='disabled'
                                                            value={
                                                                transactionDetail &&
                                                                transactionDetail.amount
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group
                                                        className='mb-3'
                                                        controlId='formBasicEmail'
                                                    >
                                                        <Form.Label>
                                                            Payer User Id
                                                        </Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            disabled='disabled'
                                                            value={
                                                                transactionDetail &&
                                                                transactionDetail.payerUserId
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group
                                                        className='mb-3'
                                                        controlId='formBasicEmail'
                                                    >
                                                        <Form.Label>
                                                            User FirstName
                                                        </Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            disabled='disabled'
                                                            value={
                                                                transactionDetail &&
                                                                transactionDetail[
                                                                    "User.firstName"
                                                                ]
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <Form.Group
                                                        className='mb-3'
                                                        controlId='formBasicEmail'
                                                    >
                                                        <Form.Label>
                                                            User LastName
                                                        </Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            disabled='disabled'
                                                            value={
                                                                transactionDetail &&
                                                                transactionDetail[
                                                                    "User.lastName"
                                                                ]
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group
                                                        className='mb-3'
                                                        controlId='formBasicEmail'
                                                    >
                                                        <Form.Label>
                                                            User Email
                                                        </Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            disabled='disabled'
                                                            value={
                                                                transactionDetail &&
                                                                transactionDetail[
                                                                    "User.email"
                                                                ]
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                    )}

                                {paymentDetail.transactionType ===
                                    "blockchain" && (
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Group
                                                    className='mb-3'
                                                    controlId='formBasicEmail'
                                                >
                                                    <Form.Label>
                                                        Status
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        disabled='disabled'
                                                        value={
                                                            transactionDetail &&
                                                            transactionDetail.status
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group
                                                    className='mb-3'
                                                    controlId='formBasicEmail'
                                                >
                                                    <Form.Label>
                                                        Amount
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        disabled='disabled'
                                                        value={
                                                            transactionDetail &&
                                                            transactionDetail.amount
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group
                                                    className='mb-3'
                                                    controlId='formBasicEmail'
                                                >
                                                    <Form.Label>
                                                        Address
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        disabled='disabled'
                                                        value={
                                                            transactionDetail &&
                                                            transactionDetail.address
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group
                                                    className='mb-3'
                                                    controlId='formBasicEmail'
                                                >
                                                    <Form.Label>
                                                        Destination Tag
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        disabled='disabled'
                                                        value={
                                                            transactionDetail &&
                                                            transactionDetail.destinationTag
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group
                                                    className='mb-3'
                                                    controlId='formBasicEmail'
                                                >
                                                    <Form.Label>
                                                        From Address
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        disabled='disabled'
                                                        value={
                                                            transactionDetail &&
                                                            transactionDetail.fromAddress
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group
                                                    className='mb-3'
                                                    controlId='formBasicEmail'
                                                >
                                                    <Form.Label>
                                                        txHash
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        disabled='disabled'
                                                        value={
                                                            transactionDetail &&
                                                            transactionDetail.txHash
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group
                                                    className='mb-3'
                                                    controlId='formBasicEmail'
                                                >
                                                    <Form.Label>
                                                        Confirmations
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        disabled='disabled'
                                                        value={
                                                            transactionDetail &&
                                                            transactionDetail.confirmations
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group
                                                    className='mb-3'
                                                    controlId='formBasicEmail'
                                                >
                                                    <Form.Label>
                                                        Confirmation Required
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        disabled='disabled'
                                                        value={
                                                            transactionDetail &&
                                                            transactionDetail.confirmationRequired
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group
                                                    className='mb-3'
                                                    controlId='formBasicEmail'
                                                >
                                                    <Form.Label>
                                                        Transaction Time
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        disabled='disabled'
                                                        value={
                                                            transactionDetail &&
                                                            transactionDetail.transactionTime
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group
                                                    className='mb-3'
                                                    controlId='formBasicEmail'
                                                >
                                                    <Form.Label>
                                                        Confirmed Time
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        disabled='disabled'
                                                        value={
                                                            transactionDetail &&
                                                            transactionDetail.confirmedTime
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>&nbsp;</Col>
                                            <Col>&nbsp;</Col>
                                        </Row>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </div>

                    {/* Payment Status history block */}
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='title'>
                            Payment Status History
                            <div className='title--bottom'></div>
                        </div>
                        <div className='mr-4'>&nbsp;</div>
                    </div>
                    <div className='card-body'>
                        <Row>
                            <Col lg={12} sm={12} md={12}>
                                <Row
                                    style={{
                                        marginLeft: "5px",
                                        marginRight: "5px",
                                    }}
                                >
                                    <Table striped bordered hover size='sm'>
                                        <thead>
                                            <tr>
                                                <th>Sr#</th>
                                                <th>Action</th>
                                                <th>Meta data</th>
                                                <th>Created At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymenStatusHistory.length ===
                                                0 && (
                                                <tr>
                                                    <td
                                                        colSpan={15}
                                                        align='center'
                                                    >
                                                        No Record Found
                                                    </td>
                                                </tr>
                                            )}
                                            {paymenStatusHistory.length > 0 &&
                                                paymenStatusHistory.map(
                                                    (item, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                {item.action}
                                                            </td>
                                                            <td>
                                                                {item.metadata}
                                                            </td>
                                                            <td>
                                                                {moment(
                                                                    item.createdAt
                                                                ).format(
                                                                    "YYYY-MM-DD H:mm:ss"
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                        </tbody>
                                    </Table>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    {/* Merchant Refund block  */}
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='title'>
                            Merchant Refund
                            <div className='title--bottom'></div>
                        </div>
                        <div className='mr-4'>&nbsp;</div>
                    </div>
                    <div className='card-body'>
                        <Row>
                            <Col lg={12} sm={12} md={12}>
                                <Row>
                                    <Col>
                                        <Form.Group
                                            className='mb-3'
                                            controlId='formBasicEmail'
                                        >
                                            <Form.Label>
                                                Merchant Refund
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                disabled='disabled'
                                                value={
                                                    merchantRefund
                                                        ? merchantRefund
                                                        : ""
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>&nbsp;</Col>
                                    <Col>&nbsp;</Col>
                                </Row>

                                <Row
                                    style={{
                                        marginBottom: "20px",
                                        float: "right",
                                    }}
                                >
                                    <Col lg={12} md={12} sm={12}>
                                        <button
                                            type='button'
                                            onClick={() => history.goBack()}
                                            className='btn-warning btn btn-sm mt-2 mr-3'
                                        >
                                            Go Back
                                        </button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Card>
            </form>
        </div>
    );
};

export default MerchantPaymentDetail;
