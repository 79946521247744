import { ToastContainer } from "react-toastify";
import { Redirect, Route, Switch } from "react-router-dom";
import { compose } from "redux";
import { useSelector } from "react-redux";

import MultiLanguage from "./components/HOC/MultiLanguage";
import RequireLogout from "./components/HOC/RequireLogout";
import RequireAuthentication from "./components/HOC/RequireAuthentication";
import SidebarCollapsed from "./components/HOC/SidebarState";

import Header from "./components/layouts/Header";
import Sidebar from "./components/layouts/Sidebar/Sidebar";
import Dashboard from "./components/Dashboard/index";

import Login from "./components/Login";

import MerchantCheckout from "./components/Admin-Merchant/MerchantCheckout";
import MerchantReports from "./components/Admin-Merchant/MerchantReports.js";
import MDATXNReports from "./components/Admin-Merchant/MDATXN.js";
import MerchantCheckoutDetail from "./components/Admin-Merchant/MerchantCheckoutDetail";
import MerchantPaymentDetail from "./components/Admin-Merchant/MerchantPaymentDetail";
import MSettlementOrder from "./components/Admin-Merchant/MSettlementOrder";
import MSettlementOrderDetail from "./components/Admin-Merchant/MSettlementOrderDetail";
import MerchantPayment from "./components/Admin-Merchant/MerchantPayment";
import MerchantRefundRequests from "./components/Admin-Merchant/MerchantRefundRequests";
import MerchantAdminKey from "./components/Admin-Merchant/MerchantKey";
import MerchantAdminKeyDetail from "./components/Admin-Merchant/MerchantAdminKeyDetail";
import PaymentCurrency from "./components/Admin-Merchant/PaymentCurrency";
import SettlementPair from "./components/Admin-Merchant/SettlementPair";
import PageLayout from "./components/Admin-Merchant/PageLayout";
import RefundFee from "./components/Admin-Merchant/RefundFee";
import FeeDistribution from "./components/Admin-Merchant/FeeDistribution";
import MerchantBalance from "./components/Admin-Merchant/MerchantBalance";
import MerchantBalanceById from "./components/Admin-Merchant/MerchantBalanceById";
import MerchantBalanceByEmail from "./components/Admin-Merchant/MerchantBalanceByEmail";
import GlobianceFeeCollection from "./components/Admin-Merchant/GlobianceFeeCollection";
import MerchantBalancesTally from "./components/Admin-Merchant/MerchantBalancesTally";
import MerchantBalancesPaymentDistribution from "./components/Admin-Merchant/MerchantBalancesPaymentDistribution";
import BalanceTallySummary from "./components/Admin-Merchant/BalanceTallySummary";
import BalanceTally from "./components/Admin-Merchant/BalanceTally";
import Blockprocessor from "./components/Admin-Merchant/Blockprocessor";
import LoggerUI from "./components/Admin-Merchant/LoggerUI";
import PaymentCurrencyNetwork from "./components/Admin-Merchant/PaymentCurrencyNetwork";
import Viewuser from "./components/Merchant/Viewuser";
import Refunds from "./components/Merchant/Refunds";
import keysList from "./components/Merchant/keysList";
import MerchantBalanceUpdateLogs from "./components/Merchant/MerchantBalanceUpdateLogs.js";
import MerchantRequest from "./components/Merchant/MerchantRequests";
import MerchantSettlementOrder from "./components/Merchant/MerchantSettlementOrder";
import MerchantKeys from "./components/Merchant/MerchantFeeRates";
import MerchantActivityLogs from "./components/Merchant/MerchantActivityLogs";
import MerchantCurrency from "./components/Admin-Merchant/MerchantCurrency";
import MerchantWithdrawal from "./components/Admin-Merchant/MerchantWithdrawal";
import MerchantUsers from "./components/Admin-Merchant/MerchantUsers";
import CreateStorePageLayoutFiles from "./components/Admin-Merchant/CreateStorePageLayoutFiles";
import MerchantSupportTickets from "./components/Admin-Merchant/MerchantSupportTickets.js";
import MerchantSupportClientRequests from "./components/Admin-Merchant/MerchantSupportClientRequests.js";
import InstaXchange from "./components/Admin-Merchant/InstaXchange.js";

import "./assets/scss/main.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import MerchantBalanceAFR from "./components/Admin-Merchant/MerchantBalanceAFR";
import MDA from "./components/Admin-Merchant/MerchantDirectAcquiring.js";
import MDATXN from "./components/Admin-Merchant/MerchantDirectAcquiringTXN.js";


const ComposedHeader = MultiLanguage("Header")(Header);
const ComposedSidebar = compose(
  MultiLanguage("Sidebar"),
  RequireAuthentication(null),
  SidebarCollapsed,
  // SelectiveComponent([], { forward: true })
)(Sidebar);

const ComposedLogin = compose(
  MultiLanguage("Login"),
  RequireLogout("/")
)(Login);

function App() {

  const authState = useSelector(store => store.auth);

  console.log("authState", authState)


  return (
    <div className="src-app">

      <ToastContainer />
      <ComposedHeader />
      <ComposedSidebar />
      <Switch>
        <Route path="/login" exact={true} component={ComposedLogin} />

        {authState.isLoggedIn ? (
          <>
            {/* Merchant Admin Dashboard */}
            <Route path="/merchant-admin-checkout" exact={true} component={MerchantCheckout} />
            <Route path="/merchant-reports" exact={true} component={MerchantReports} />
            <Route path="/mda-txn-reports" exact={true} component={MDATXNReports} />
            <Route path="/merchant-admin-checkout-detail/:id" exact={true} component={MerchantCheckoutDetail} />
            <Route path="/merchant-admin-payment-detail/:id" exact={true} component={MerchantPaymentDetail} />
            <Route path="/merchant-admin-settlement-order" exact={true} component={MSettlementOrder} />
            <Route path="/merchant-admin-settlement-order-detail/:id" exact={true} component={MSettlementOrderDetail} />
            <Route path="/merchant-admin-payment" exact={true} component={MerchantPayment} />
            <Route path="/merchant-admin-refund-request" exact={true} component={MerchantRefundRequests} />
            <Route path="/merchant-admin-keys" exact={true} component={MerchantAdminKey} />
            <Route path="/merchant-admin-keys-detail/:id" exact={true} component={MerchantAdminKeyDetail} />
            <Route path="/payment-gateway-currency" exact={true} component={PaymentCurrency} />
            <Route path="/settlement-pair" exact={true} component={SettlementPair} />
            <Route path="/merchant-admin-refund-fee" exact={true} component={RefundFee} />
            <Route path="/merchant-admin-fee-distribution" exact={true} component={FeeDistribution} />
            <Route path="/merchant-admin-balances" exact={true} component={MerchantBalance} />
            <Route path="/merchant-admin-balances-by-id" exact={true} component={MerchantBalanceById} />
            <Route path="/merchant-admin-balances-by-email" exact={true} component={MerchantBalanceByEmail} />
            <Route path="/merchant-admin-balances-afr" exact={true} component={MerchantBalanceAFR} />
            <Route path="/globiance-fee-collection" exact={true} component={GlobianceFeeCollection} />
            <Route path="/merchant-balances-tally" exact={true} component={MerchantBalancesTally} />
            <Route path="/merchant-balances-payment-distribution" exact={true} component={MerchantBalancesPaymentDistribution} />
            <Route path="/balance-tally-summary" exact={true} component={BalanceTallySummary} />
            <Route path="/balance-tally" exact={true} component={BalanceTally} />
            <Route path="/merchant-blockprocessor" exact={true} component={Blockprocessor} />
            <Route path="/merchant-LoggerUI" exact={true} component={LoggerUI} />
            <Route path="/merchant-page-layout" exact={true} component={PageLayout} />
            <Route path="/merchant-currency" exact={true} component={MerchantCurrency} />
            <Route path="/merchant-withdrawal" exact={true} component={MerchantWithdrawal} />
            <Route path="/merchant-users" exact={true} component={MerchantUsers} />
            <Route path="/add-page-layout-file/:id" exact={true} component={CreateStorePageLayoutFiles} />

            <Route path="/merchant-checkout" exact={true} component={Viewuser} />
            <Route path="/merchant-refunds" exact={true} component={Refunds} />
            <Route path="/keyslist/:email" exact={true} component={keysList} />
            <Route
              path="/merchant-balance-update-logs"
              exact={true}
              component={MerchantBalanceUpdateLogs}
            />
            <Route
              path="/merchant-request"
              exact={true}
              component={MerchantRequest}
            />
            <Route path="/merchant-keys" exact={true} component={MerchantKeys} />
            <Route
              path="/merchant-settlement-order"
              exact={true}
              component={MerchantSettlementOrder}
            />
            <Route
              path="/merchant-activity-logs"
              exact={true}
              component={MerchantActivityLogs}
            />

            <Route
              path="/payment-currency-network/:action/:id"
              exact={true}
              component={PaymentCurrencyNetwork}
            />
            <Route path="/merchant-support-tickets" exact={true} component={MerchantSupportTickets} />
            <Route path="/merchant-support-clients-requests" exact={true} component={MerchantSupportClientRequests} />
            <Route path="/merchant-instaxchange" exact={true} component={InstaXchange} />
            <Route path="/merchant-direct-acquiring" exact={true} component={MDA} />
            <Route path="/merchant-direct-acquiring-txn" exact={true} component={MDATXN} />
            <Route path="/" exact={true} component={Dashboard} />
          </>
        ) : (
          <Redirect to="/login" />
        )}

        <Redirect to="/login" />

      </Switch>
    </div>
  );
}

export default App;
