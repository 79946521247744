import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "../common/Table";
import ToggleModal from "../common/ToggleModal";
import {
  DateFilter,
  DefaultHeadFormatter,
  GeneralStateUpdaterNew,
  IconHeader,
  TableLoader,
  ViewOnlyForm,
} from "../../utils/Table";
import moment from "moment";
import { API_MERCHANT_ROOT } from "../../helper/constant";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { dateFilter, textFilter } from "react-bootstrap-table2-filter";

const mbAFRColumn = [
  {
    dataField: "srNo",
    text: "SR.NO",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: ["balanceId"],
    text: "Balance ID",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "creditAmountReleased",
    text: "creditAmountReleased",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "creditAmountDebited",
    text: "creditAmountDebited",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: ["note"],
    text: "Note",
    sort: true,
    classes: "u-center",
    headerClasses: "u-cursor-pointer",
    filter: textFilter(),
    headerFormatter: IconHeader(faSort),
  },
  {
    dataField: "actions",
    text: "Actions",
    classes: "u-center",
    formatter: (cell, row, rowIndex, x) => {
      if (cell)
        return cell.map((e) => {
          if (e.modalName) return <ToggleModal {...e} />;
          return e;
        });
    },
  },
];

class MerchantBalanceAFR extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      merchantBalance: { data: [], totalRecords: 0, loading: false },
      pageSize: 10,
      currentPage: 0,
      searchParams: { merchantEmail: "" },
    };

    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getMerchantBalance(0);
  }

  handleChange = ({ currentTarget: input }) => {
    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  handlePage = (page) => {
    this.setState({ currentPage: page });
    this.getMerchantBalance(page);
  };

  handleSearch = () => {
    this.getMerchantBalance(0);
  };

  getMerchantBalance(page) {
    var params = {};

    console.log(params);
    this.generalStateUpdater("merchantBalance", {
      path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-balance/get-all-merchant-balance-afr`,
      method: "POST",
      data: params,
      postProcessor: (e) => {
        const View = {
          modalName: "View Merchant Balance AFR",
          children: <ViewOnlyForm data={e} />,
          backdrop: "static",
          centered: true,
          disableSubmit: true,
          keyboard: false,
        };

        return {
          ...e,
          actions: [View],
        };
      },
    });
  }

  renderTableData(name) {
    if (this.state[name].loading === true) return TableLoader("srNo");
    return {
      data: this.state[name].data.map((e, i) => {
        if (e.createdAt != null) {
          e.createdAt = moment(e.createdAt).format("YYYY-MM-DD");
        }
        return {
          srNo: i + 1,
          ...e,
        };
      }),
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          <div>
            <Link
              to="/merchant-admin-balances"
              className="btn btn-outline-dark tab-btn"
              style={{ marginRight: "10px" }}
            >
              Merchant Balances
            </Link>
            <Link
              to="/merchant-admin-balances-by-id"
              className="btn btn-outline-dark tab-btn"
              style={{ marginRight: "10px" }}
            >
              Merchant Balances By Id
            </Link>
            <Link
              to="/merchant-admin-balances-by-email"
              className="btn btn-outline-dark tab-btn"
            >
              Merchant Balances By Email
            </Link>
            <Link
              to="/merchant-admin-balances-afr"
              className="btn btn-outline-dark tab-btn active"
            >
              Merchant Balances AFR
            </Link>
          </div>
        </Card>
        <Card className="custom-card-1">
          <div className="title">
            Merchant: Balances AFR
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Row>
              <Col lg={12} sm={12} md={12}>
                <Table
                  columns={mbAFRColumn}
                  className="table1"
                  tableName="Merchant Balance AFR"
                  keyField="srNo"
                  onRefresh={this.getMerchantBalance.bind(this)}
                  {...this.renderTableData("merchantBalance")}
                />
              </Col>
            </Row>

            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantBalanceAFR;
