import React from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";

import {
  GeneralStateUpdaterNew,
} from "../../utils/Table";
import * as xlsx from "xlsx";
import ReactSelect from 'react-select';
import { API_MERCHANT_ROOT } from "../../helper/constant";
import { SelectOption } from "../common/RenderCustomView";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { AddNoti } from "../../helper/Notification";
import moment from "moment";

class MerchantReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currFY: {
        data: null, loading: false
      },
      prevFY: {
        data: null, loading: false
      },
      reportFlexible: {
        data: null, loading: false
      },
      searchParams: { to: "", from: "", merchantKeyId: "", merchantKeyIdCurr: "", merchantKeyIdPrev: "" },
      storeMapping: [],
    };


    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getAllStores();

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' };

    today = yyyy + '-' + mm + '-' + dd;

    var toDate = new Date(today + 'T23:59:00');

    var fromDate = new Date(toDate);
    fromDate.setDate(fromDate.getDate() - 7);

    var fd = String(fromDate.getDate()).padStart(2, '0');
    var fm = String(fromDate.getMonth() + 1).padStart(2, '0');
    var fy = fromDate.getFullYear();

    var fromdate = fy + '-' + fm + '-' + fd;

    const searchParams = { ...this.state.searchParams };
    searchParams["to"] = moment(toDate.toLocaleString('en-US', options)).format("YYYY-MM-DD HH:mm:ss");
    searchParams["from"] = fromdate + ' 00:00:00';

    this.setState({ searchParams });
  }

  handleChange = ({ currentTarget: input }) => {

    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  handleSearch = () => {
    if (this.state.searchParams.merchantKeyId) {
      this.getReportFlexible();
    } else {
      AddNoti('Select Store.')
    }
  }

  getReportFlexible() {
    const { to, from, merchantKeyId } = this.state.searchParams;
    var params = {
    };

    if (to != '') {
      params.to = moment(to).toISOString();
    }
    if (from != '') {
      params.from = moment(from).toISOString();
    }
    if (merchantKeyId != '') {
      params.merchantKeyId = merchantKeyId;
    }

    {
      params.merchantKeyId && this.generalStateUpdater("reportFlexible", {
        path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/reports/get-report-flexible`,
        method: "POST",
        data: params
      });
    }
  }

  getReportPrevFy() {
    const { merchantKeyIdPrev } = this.state.searchParams;
    var params = {
    };
    if (merchantKeyIdPrev != '') {
      params.merchantKeyId = merchantKeyIdPrev;
    }

    {
      params.merchantKeyIdPrev && this.generalStateUpdater("prevFY", {
        path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/reports/get-report-prev-fy`,
        method: "POST",
        data: params,
      });
    }
  }

  getReportCurrFy() {
    const { merchantKeyIdCurr } = this.state.searchParams;
    var params = {
    };

    if (merchantKeyIdCurr != '') {
      params.merchantKeyId = merchantKeyIdCurr;
    }

    {
      params.merchantKeyIdCurr && this.generalStateUpdater("currFY", {
        path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/reports/get-report-cur-fy`,
        method: "POST",
        data: params
      });
    }
  }

  getAllStores() {
    MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/get-all-merchant-key`)
      .then((resp) => {
        if (resp.status === 200 || resp.status === 201) {

          this.setState({ store: resp.data.data });
          const storeMap =
            resp.data.data != null
              ? resp.data.data.map((store) => ({
                label: `${store.labelName}`,
                value: store.uniqueId,
              }))
              : [{ label: "Loading", value: "" }];

          const options = [...SelectOption, ...storeMap]
          console.log("==", storeMap);
          this.setState({ storeMapping: options });

        } else {
          //AddNoti(ParseError(resp), { type: "error" });
        }
      })
      .catch((e) => {
        //AddNoti(ParseError(e), { type: "error" });
      });
  };

  generateExcelFile(data) {
    const workbook = xlsx.utils.book_new();
    const overviewData = [
      ['Checkout Count', data.checkoutCount],
      ['Payment Count', data.paymentCount],
    ];

    // Iterate over totalCollectionSettled and add key-value pairs to the overviewData array
    overviewData.push([]);
    overviewData.push(['Total Collection ( Settled )']);
    for (const key in data.totalCollectionSettled) {
      overviewData.push([key, data.totalCollectionSettled[key]]);
    }

    // Iterate over totalCollectionIn and add key-value pairs to the overviewData array
    overviewData.push([]);
    overviewData.push(['Total Collection ( In Payment Currency )']);
    for (const key in data.totalCollectionIn) {
      overviewData.push([key, data.totalCollectionIn[key]]);
    }

    // Convert the overviewData into a sheet object
    const overviewSheet = xlsx.utils.aoa_to_sheet(overviewData);

    // Set the column widths for better visibility
    const columnWidths = [{ wch: 20 }, { wch: 20 }];
    overviewSheet['!cols'] = columnWidths;

    // Append the overviewSheet to the workbook
    xlsx.utils.book_append_sheet(workbook, overviewSheet, 'Overview');

    if (data.feeSettlements && data.feeSettlements.length > 0) {
      const statementsSheet = xlsx.utils.json_to_sheet(data.feeSettlements);
      xlsx.utils.book_append_sheet(workbook, statementsSheet, 'Statements');
    }
    if (data.withdrawLogs && data.withdrawLogs.length > 0) {
      const withdrawalsSheet = xlsx.utils.json_to_sheet(data.withdrawLogs);
      xlsx.utils.book_append_sheet(workbook, withdrawalsSheet, 'Withdrawals');
    }

    const workbookBuffer = xlsx.write(workbook, { type: 'array', bookType: 'xlsx' });

    const filename = `report_${Date.now()}.xlsx`;

    const blob = new Blob([workbookBuffer], { type: 'application/octet-stream' });

    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          <div className="title">
            Merchant Reports.
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Card className="custom-card-1">
              <div className="card-body">
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>From:</Form.Label>
                      <Form.Control type="datetime-local" value={this.state.searchParams.from} name="from" onChange={this.handleChange} style={{ height: "35px" }} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>To:</Form.Label>
                      <Form.Control type="datetime-local" value={this.state.searchParams.to} name="to" onChange={this.handleChange} style={{ height: "35px" }} />
                    </Form.Group>
                  </Col>

                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Store:</Form.Label>
                      <ReactSelect placeholder="Select Store..." defaultValue={""} options={this.state.storeMapping} onChange={(e) => {
                        this.setState({
                          searchParams: {
                            ...this.state.searchParams,
                            merchantKeyId: e.value
                          },
                          reportFlexible: {
                            data: null,
                            loading: false
                          }
                        })
                      }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: "25px" }}>
                    <Button variant="primary" type="button" onClick={() => this.handleSearch()} style={{ width: '125px', fontSize: '16px' }}>
                      Submit
                    </Button>

                    {this.state.reportFlexible.data && <Button onClick={() => this.generateExcelFile(this.state.reportFlexible.data)}>Download</Button>}
                  </Col>
                </Row>
              </div>
            </Card>
            <Card className="custom-card-1">
              <div className="d-flex justify-content-between items-center mb-4 border rounded-lg p-2">
                <h4 className="text-lg font-semibold m-0 d-flex" style={{ alignItems: 'center' }}>Download Previous Financial Year (2023-2024)</h4>
                <Form.Group style={{ width: "15%" }} controlId="formBasicEmail">
                  <ReactSelect placeholder="Select Store..." defaultValue={""} options={this.state.storeMapping} onChange={(e) => {
                    this.setState(
                      (prevState) => ({
                        searchParams: {
                          ...prevState.searchParams,
                          merchantKeyIdPrev: e.value
                        },
                        prevFY: {
                          data: null,
                          loading: false
                        }
                      })
                    );
                  }} />
                </Form.Group>
                <span className="d-flex align-items-center">
                  <Button variant="primary" type="button" onClick={() => this.getReportPrevFy()} className="mx-3">
                    Submit
                  </Button>
                  {this.state.prevFY.data ? <Button variant="success" onClick={() => this.generateExcelFile(this.state.prevFY.data)}>Download</Button> : <span></span>}
                </span>
              </div>
              <div className="d-flex justify-content-between items-center mb-4 border rounded-lg p-2">
                <h4 className="text-lg font-semibold m-0 d-flex" style={{ alignItems: 'center' }}>Download Current Financial Year (2024-2025)</h4>

                <Form.Group style={{ width: "15%" }} controlId="formBasicEmail">
                  <ReactSelect placeholder="Select Store..." defaultValue={""} options={this.state.storeMapping} onChange={(e) => {
                    this.setState(
                      (prevState) => ({
                        searchParams: {
                          ...prevState.searchParams,
                          merchantKeyIdCurr: e.value
                        },
                        currFY: {
                          data: null,
                          loading: false
                        }
                      })
                    );
                  }} />
                </Form.Group>
                <span className="d-flex align-items-center">
                  <Button variant="primary" type="button" onClick={() => this.getReportCurrFy()} className="mx-3">
                    Submit
                  </Button>
                  {this.state.currFY.data ? <Button variant="success" onClick={() => this.generateExcelFile(this.state.currFY.data)}>Download</Button> : <span></span>}
                </span>
              </div>
            </Card>
            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantReports;
