import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faMoneyBillWave,
  faStore,
  faClock,
  faCheckCircle,
  faDollarSign,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";

const IconMapping = {
  checkoutCount24Hrs: { icon: faShoppingCart, label: "Checkouts (24Hrs)", color: "text-primary" },
  lastCheckoutTime: { icon: faClock, label: "Last Checkout", color: "text-secondary" },
  paymentCount24Hrs: { icon: faMoneyBillWave, label: "Payments (24Hrs)", color: "text-success" },
  last24HrsPaymentSumUsd: { icon: faDollarSign, label: "Payments (24Hrs) USD", color: "text-success" },
  lastCompletedCheckoutTime: { icon: faCheckCircle, label: "Last Completed Checkout", color: "text-info" },
  lastWeekPaymentSumUsd: { icon: faChartLine, label: "Payments (Last Week) USD", color: "text-dark" },
  totalStores: { icon: faStore, label: "Total Stores", color: "text-warning" },
};

const StatsCard = ({ value, label, icon, color }) => {
  return (
    <Card className="shadow-sm border-light p-3 mb-3 d-flex align-items-start">
      <Card.Body className="d-flex align-items-center">
        <FontAwesomeIcon icon={icon} size="3x" className={`${color} me-3 mr-4`} />
        <div>
          <h2 className="fw-bolder">{typeof value === "string" ? new Date(value).toLocaleString() : value}</h2>
          <p className="text-muted mb-0">{label}</p>
        </div>
      </Card.Body>
    </Card>
  );
};

const StatsGrid = ({ statsData }) => {
  return (
    <Row className="g-4 p-4 text-ce">
      {/* Checkout Stats */}
      <Col xs={12} sm={6} md={4} lg={4}>
        <StatsCard {...IconMapping.checkoutCount24Hrs} value={statsData.checkoutCount24Hrs} />
      </Col>
      <Col xs={12} sm={6} md={4} lg={4}>
        <StatsCard {...IconMapping.lastCheckoutTime} value={statsData.lastCheckoutTime} />
      </Col>
      <Col xs={12} sm={6} md={4} lg={4}>
        <StatsCard {...IconMapping.lastCompletedCheckoutTime} value={statsData.lastCompletedCheckoutTime} />
      </Col>

      <Col xs={12} sm={6} md={4} lg={4}>
        <StatsCard {...IconMapping.paymentCount24Hrs} value={statsData.paymentCount24Hrs} />
      </Col>
      <Col xs={12} sm={6} md={4} lg={4}>
        <StatsCard {...IconMapping.last24HrsPaymentSumUsd} value={statsData.last24HrsPaymentSumUsd} />
      </Col>
      <Col xs={12} sm={6} md={4} lg={4}>
        <StatsCard {...IconMapping.lastWeekPaymentSumUsd} value={statsData.lastWeekPaymentSumUsd} />
      </Col>

      <Col xs={12} sm={6} md={4} lg={4} className="mx-auto">
        <StatsCard {...IconMapping.totalStores} value={statsData.totalStores} />
      </Col>
    </Row>
  );
};

export default StatsGrid;
