import React from "react";
import moment from "moment";
import { Card, Row, Col, Form, Button, Table } from "react-bootstrap";

import {
  GeneralStateUpdaterNew,
} from "../../utils/Table";
import ReactSelect from 'react-select';
import { API_MERCHANT_ROOT } from "../../helper/constant";
import { SelectOption } from "../common/RenderCustomView";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import * as XLSX from "xlsx";

class MDATXNReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mdatxn: {
        data: null, loading: false
      },
      searchParams: { to: "", from: "", merchantKeyId: "" },
      storeMapping: [],
    };


    this.generalStateUpdater = GeneralStateUpdaterNew.bind(this);
  }

  componentDidMount() {
    this.getAllStores();

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    var yyyy = today.getFullYear();
    var options = { year: 'numeric', month: '2-digit', day: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' };

    today = yyyy + '-' + mm + '-' + dd;

    var toDate = new Date(today + 'T23:59:00'); // Set "to" date to today at 23:59

    var fromDate = new Date(toDate); // Create a copy of "to" date
    fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the "from" date

    var fd = String(fromDate.getDate()).padStart(2, '0');
    var fm = String(fromDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    var fy = fromDate.getFullYear();

    var fromdate = fy + '-' + fm + '-' + fd;

    const searchParams = { ...this.state.searchParams };
    // searchParams["to"] = toDate.toISOString().slice(0, 19).replace('T', ' '); // Convert "to" date to YYYY-MM-DD HH:mm:ss format
    searchParams["to"] = moment(toDate.toLocaleString('en-US', options)).format("YYYY-MM-DD HH:mm:ss");
    searchParams["from"] = fromdate + ' 00:00:00'; // Set "from" date to YYYY-MM-DD 00:00:00 format

    this.setState({ searchParams }, () => this.getMerchantCheckout());
  }

  handleChange = ({ currentTarget: input }) => {

    const searchParams = { ...this.state.searchParams };
    searchParams[input.name] = input.value;

    this.setState({ searchParams });
  };

  handleSearch = () => {
    this.getMerchantCheckout();
  }

  getMerchantCheckout(page) {
    const { to, from, merchantKeyId } = this.state.searchParams;
    console.log("key=", merchantKeyId);
    var params = {
    };

    if (to != '') {
      params.to = moment(to).toISOString();
    }
    if (from != '') {
      params.from = moment(from).toISOString();
    }
    if (merchantKeyId != '') {
      params.merchantKeyId = merchantKeyId;
    }

    // console.log(params);
    {
      params.merchantKeyId && this.generalStateUpdater("mdatxn", {
        path: `${API_MERCHANT_ROOT}/admin-dashboard-api-service/reports/get-acquirer-report-by-store`,
        method: "POST",
        data: params
      });
    }
  }

  getAllStores() {
    MerchantAxiosInstance.post(`${API_MERCHANT_ROOT}/admin-dashboard-api-service/merchant-key/get-all-merchant-key`)
      .then((resp) => {
        if (resp.status === 200 || resp.status === 201) {

          this.setState({ store: resp.data.data });
          const storeMap =
            resp.data.data != null
              ? resp.data.data.map((store) => ({
                label: `${store.labelName}`,
                value: store.uniqueId,
              }))
              : [{ label: "Loading", value: "" }];

          const options = [...SelectOption, ...storeMap]
          console.log("==", storeMap);
          this.setState({ storeMapping: options });

        } else {
          //AddNoti(ParseError(resp), { type: "error" });
        }
      })
      .catch((e) => {
        //AddNoti(ParseError(e), { type: "error" });
      });
  };


  downloadExcel(data) {
    const worksheet = XLSX.utils.aoa_to_sheet([
      ["Checkout Count", "Payment Count", "Total MDA Transactions", "Successful MDA Transactions", "Failed MDA Transactions", "Pending MDA Transactions"],
      [data.checkoutCount, data.paymentCount, data.mdaTxnCount.total, data.mdaTxnCount.success, data.mdaTxnCount.failed, data.mdaTxnCount.pending]
    ]);

    let rowIndex = 5;
    XLSX.utils.sheet_add_aoa(worksheet, [["Failed Reasons", "Count"]], { origin: `A${rowIndex}` });
    rowIndex++;

    Object.entries(data.failedReasons).forEach(([reason, count]) => {
      XLSX.utils.sheet_add_aoa(worksheet, [[reason, count]], { origin: `A${rowIndex}` });
      rowIndex++;
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "MDA_Txn_Data");
    XLSX.writeFile(workbook, "MDA_Txn_Data.xlsx");
  };


  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          <div className="title">
            MDA-TXN: Reports
            <div className="title--bottom"></div>
          </div>
          <div className="card-body">
            <Card className="custom-card-1">
              <div className="card-body">
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>From:</Form.Label>
                      <Form.Control type="datetime-local" value={this.state.searchParams.from} name="from" onChange={this.handleChange} style={{ height: "35px" }} />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>To:</Form.Label>
                      <Form.Control type="datetime-local" value={this.state.searchParams.to} name="to" onChange={this.handleChange} style={{ height: "35px" }} />
                    </Form.Group>
                  </Col>

                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Store:</Form.Label>
                      <ReactSelect defaultValue={""} options={this.state.storeMapping} onChange={(e) => {
                        this.setState({
                          searchParams: {
                            ...this.state.searchParams,
                            merchantKeyId: e.value
                          }
                        })
                      }} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ marginTop: "25px" }}>
                    <Button variant="primary" type="button" onClick={() => this.handleSearch()} style={{ width: '125px', fontSize: '16px' }}>
                      Search
                    </Button>
                  </Col>
                </Row>
              </div>
            </Card>
            {this.state.mdatxn.data && <Card className="custom-card-1 p-4">
              <div className="d-flex justify-content-between items-center mb-4">
                <h2 className="text-lg font-semibold">MDA TXN Data: {this.state.searchParams.merchantKeyId}</h2>
                <Button onClick={() => this.downloadExcel(this.state.mdatxn.data)}>Download Excel</Button>
              </div>

              <Table className="w-full border">
                <thead>
                  <tr className="bg-gray-200 text-left">
                    <th className="p-2 border">Metric</th>
                    <th className="p-2 border">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-2 border">Checkout Count</td>
                    <td className="p-2 border">{this.state.mdatxn.data.checkoutCount}</td>
                  </tr>
                  <tr>
                    <td className="p-2 border">Payment Count</td>
                    <td className="p-2 border">{this.state.mdatxn.data.paymentCount}</td>
                  </tr>
                  <tr>
                    <td className="p-2 border">Total MDA Transactions</td>
                    <td className="p-2 border">{this.state.mdatxn.data.mdaTxnCount.total}</td>
                  </tr>
                  <tr>
                    <td className="p-2 border">Successful MDA Transactions</td>
                    <td className="p-2 border">{this.state.mdatxn.data.mdaTxnCount.success}</td>
                  </tr>
                  <tr>
                    <td className="p-2 border">Failed MDA Transactions</td>
                    <td className="p-2 border">{this.state.mdatxn.data.mdaTxnCount.failed}</td>
                  </tr>
                  <tr>
                    <td className="p-2 border">Pending MDA Transactions</td>
                    <td className="p-2 border">{this.state.mdatxn.data.mdaTxnCount.pending}</td>
                  </tr>

                  <tr className="bg-gray-200 text-left">
                    <th className="p-2 border" colSpan="2">Failed Reasons</th>
                  </tr>
                  {Object.entries(this.state.mdatxn.data.failedReasons).map(([reason, count], index) => (
                    <tr key={index}>
                      <td className="p-2 border">{reason}</td>
                      <td className="p-2 border">{count}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>}

            <br />
          </div>
        </Card>
      </div>
    );
  }
}

export default MDATXNReports;
